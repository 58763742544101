import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Подготовка искового заявления | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Исковое заявление – это эффективный инструмент истца в борьбе за свои законные права и интересы. Поэтому к составлению иска следует подходить индивидуально, в зависимости от обстоятельств конкретной ситуации." />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/podgotovka-iskovogo-zayavleniya'
				name="Подготовка искового заявления | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Исковое заявление – это эффективный инструмент истца в борьбе за свои законные права и интересы. Поэтому к составлению иска следует подходить индивидуально, в зависимости от обстоятельств конкретной ситуации."
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Подготовка искового заявления" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Подготовка искового заявления"}
			
				html={

				`

				<p class="TextText">
				Процессуальными документами являются: исковое заявление; заявление о выдаче судебного приказа; заявление по делу, рассматриваемому в порядке особого производства (заявление об установлении юридических фактов); заявления об оспаривании решения, действия (бездействия) органа государственной власти, органа местного самоуправления, должностного лица, государственного или муниципального служащего; заявление о применении обеспечительных мер; ходатайство; апелляционная жалоба; кассационная жалоба; надзорная жалоба; иные документы, предусмотренные процессуальным законодательством.
				</p>

				<p class="TextText">
				К процессуальным документам, законодательством предъявляются определенные требования. Требования предъявляются не только к форме, но и к содержанию. Не соблюдение требований к форме документа влечет недействительность документа. А несоблюдение требований к содержанию приводит к негативным результатам для заявителя. Можно обратиться в юридическую компанию, чтобы заказать у них абонентское обслуживание, таким образом все ваши дела будут носить законный характер.
				</p>

				<p class="TextText">
					<b>Правила составления грамотного искового заявления</b>
				</p>

				<p class="TextText">
				Исковое заявление – это эффективный инструмент истца в борьбе за свои законные права и интересы. Поэтому к составлению иска следует подходить индивидуально, в зависимости от обстоятельств конкретной ситуации.

				Некоторые граждане, составляя исковое заявление, используют шаблоны, образцы. Нужно понимать, что каждое дело обладает определенными специфическими особенностями, которые, как правило, не могут быть отражены в шаблонах. При использовании подобного подхода могут быть упущены важные детали, что может привести к негативным последствиям, иногда и к отказу в удовлетворении иска.

				Подготовка искового заявления должно осуществляться в строгом соответствии с требованиями законодательства. Перечень требований приведен в ст. 131 Гражданского процессуального Кодекса РФ (ГПК РФ).

				Составление искового заявления - важный этап подготовки к судебному процессу. Как правильно составить исковое заявление?

				Исковое заявление составляется в произвольной форме согласно определенным правилам. Грамотно составленное исковое заявление во многом определяет успех или неудачу судебного разбирательства.
				</p>

				<p class="TextText">
				При составлении искового заявления необходимо обратить внимание на наличие в документе ряда обязательных реквизитов: наименование суда, в который подается иск;
				полные данные об истце и ответчике; размер иска и размер государственной пошлины (в случае если они есть); описание обстоятельств дела, послуживших основанием для подачи заявления; доказательства, на которых базируется позиция истца; ссылки на нормативно-правовые документы, являющиеся обоснованием иска;
				перечень прилагаемых документов; подпись заявителя или уполномоченного им лица (в этом случае к заявлению прикладывается доверенность).
				</p>

				<p class="TextText">
				Вместе с тем, необходимо понимать, что строгое соблюдение всех формальных правил составления искового заявления в лучшем случае является гарантией того, что исковое заявление в суд будет принято к рассмотрению. Успех же самого дела зависит от иного.

				Здесь важно, насколько четко при составлении искового заявления заявитель сможет обосновать свою позицию, а главное – насколько грамотно подберет нормативно-правовую базу. Дело в том, что в законе нет описания конкретных ситуаций, там указаны общие нормы, толковать которые часто можно по-разному.
				</p>

				<p class="TextText">
				Таким образом, на первой стадии составления искового заявления необходимо четко сформулировать свою претензию и выдвинуть адекватные требования к ответчику (не завышенные и не заниженные).

				Также в соответствии с правилами составления искового заявления, в заявлении необходимо по возможности кратко и четко сформулировать суть дела и свои претензии. Все исковые требования истца должны быть обоснованы, и здесь пригодится знание российского законодательства.

				Юридическая компания может оказать услугу представительства в суде.

				Помимо искового заявления, в суд нужно предоставить необходимые документы, а также доказательства своей правоты.
				</p>

				<p class="TextText">
				В самом исковом заявлении необходимо перечислить свидетелей со стороны истца, если таковые будут фигурировать в судебном процессе. Какие именно документы необходимо приложить к исковому заявлению в каждом конкретном случае, а также как оформить само заявление, на какие законы сослаться при обосновании своих претензий к ответчику и какие доказательства предоставить – это все профессиональные обязанности вашего адвоката.
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
